<script setup lang="ts">
import AgentTopbar from '~/components/agent/AgentTopbar.vue';
import ConfigureAgentModal from '~/components/modals/ConfigureAgentModal.vue';

const modal = useModal();
const agentsStore = useAgentsStore();
const userStore = useUserStore();

const { startStream, stopAllStreams } = useStreamStore();
const { campaigns } = storeToRefs(agentsStore);
const { isEnabled } = userStore;
const { routeName } = useRouteName();

const { data: agent } = await useAsyncData(() => agentsStore.loadAgent());
await useLazyAsyncData('SDR/campaigns', () => agentsStore.loadCampaigns());

onMounted(() => {
  if (!isEnabled('sdr-agent')) {
    return navigateTo('/');
  }
  if (agent.value?.status === 'new') {
    modal.open(ConfigureAgentModal);
  }
  campaigns.value?.forEach((campaign) => {
    if (campaign.state === 'inProgress') {
      startStream({
        spellId: campaign.spellId,
        runId: campaign.id,
        type: 'agent',
      });
    }
  });
});

onBeforeRouteLeave(() => {
  stopAllStreams();
});

const pageHeaders = {
  'agent.config': {
    title: 'Configuration',
    icon: 'i-ph-gear',
    description:
      'Automate prospecting, lead generation, and initial outreach while maintaining a personalized touch. Seamlessly integrate with your existing tools to boost your pipeline and free up your human SDRs for high-value tasks.',
  },
  'agent.analytics': {
    title: 'Analytics',
    icon: 'i-ph-chart-bar',
    description: 'View the analytics for your agent.',
  },
  'agent.campaigns': {
    title: 'Campaigns',
    icon: 'i-ph-paper-plane-tilt',
    description: 'View the campaigns for your agent.',
  },
};
</script>
<template>
  <div
    class="grow flex flex-col bg-white justify-start max-h-screen overflow-hidden"
  >
    <AgentTopbar />
    <NuxtLayout name="spell-tab" :wide="true">
      <div
        v-if="routeName && pageHeaders[routeName]"
        class="flex flex-col items-start gap-3 mr-auto pt-l pb-8"
      >
        <span class="bg-gray-50 rounded-2xl border border-gray-300 flex p-3">
          <UIcon
            :name="pageHeaders[routeName].icon"
            class="w-14 h-14 text-primary-500"
          />
        </span>
        <p class="main-title">{{ pageHeaders[routeName].title }}</p>
        <p class="body dimmed w-1/2">
          {{ pageHeaders[routeName].description }}
        </p>
      </div>
      <NuxtPage keepalive />
    </NuxtLayout>
  </div>
</template>
